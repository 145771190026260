<template>
  <div>
    <header-slot />
    <b-nav
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'ced-settings-commissions-customer-service' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >Customer Service</b-nav-item>
      <b-nav-item
        :to="{ name: 'ced-settings-commissions-financial' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >Financial</b-nav-item>
      <b-nav-item
          :to="{ name: 'ced-settings-commissions-sales' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
      >Sales</b-nav-item>
      <b-nav-item
        :to="{ name: 'ced-settings-commissions-financial-dynamic' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >Dynamic Commissions</b-nav-item>
      <b-nav-item
        :to="{ name: 'ced-settings-commissions-connection' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >Connection</b-nav-item>
    </b-nav>
    <b-card class="px-1 pb-1">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      idToSend: 0,
      typeCommission: 0,
      vMoney: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        maxlength: 10,
      },
      openListTrackingCedCommissionsOn: false,
      commissionsData: [ // typeCommission: 1 amount 2 percentage, category: 1 action 2 charge
      ],
      rolesCommissionData: [],
      isBusy: false,
      isMoving: true,
      typeTable: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    await this.getCommissionData()
    await this.getCedCommissionRoles()
  },
  methods: {
    closeListTrackingCedCommission() {
      this.openListTrackingCedCommissionsOn = false
    },
    openListTrackingCedCommissions(item, typeCommission, type) {
      this.idToSend = type === 1 ? item.id : item.real_id
      this.typeCommission = typeCommission
      this.typeTable = type
      this.openListTrackingCedCommissionsOn = true
    },
    editCommission(commissionData, index, type) {
      if (type === 1) {
        this.commissionsData[index].savedData = this.commissionsData[index].value
        this.commissionsData[index].editing = true
      } else {
        this.rolesCommissionData[index].savedData = this.rolesCommissionData[index].value
        this.rolesCommissionData[index].editing = true
      }
    },
    cancelEditCommission(commissionData, index, type) {
      if (type === 1) {
        this.commissionsData[index].value = this.commissionsData[index].savedData
        this.commissionsData[index].editing = false
      } else {
        this.rolesCommissionData[index].value = this.rolesCommissionData[index].savedData
        this.rolesCommissionData[index].editing = false
      }
    },
    async saveCommission(commissionData, index, type) {
      try {
        if (this.commissionsData[index].value !== this.commissionsData[index].savedData) {
          const result = await this.$refs.form.validate()
          if (result) {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              const data = await CeSetCommissionsService.saveChangeCommission({
                new_value: commissionData.value,
                old_value: commissionData.savedData,
                type_data_commission: commissionData.typeCommission,
                type_commission: commissionData.id,
                type_commission_description: commissionData.description,
                created_by: this.currentUser.user_id,
                status: 1, // Pending
                user_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                type,
              })
              if (data.status === 200) {
                await this.getCommissionData()
                await this.getCedCommissionRoles()
                this.showGenericSuccessSwal({})
              }
            }
          }
        } else {
          this.showWarningSwal('Invalid Change', 'The entered value is the same as the old one')
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async reloadPage() {
      await this.getCommissionData()
      await this.getCedCommissionRoles()
    },
    async getCommissionData() {
      try {
        let fake = []
        this.commissionsData = []
        this.isBusy = true
        const data = await CeSetCommissionsService.getCommissionData()
        if (data.status === 200) {
          fake = data.data
          fake.forEach(commission => {
            commission.editing = false
            commission.savedData = 0
            commission.value = parseFloat(commission.value)
          })
          this.commissionsData = fake
          setTimeout(() => {
            this.isBusy = false
          }, 900)
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
        this.isBusy = false
      }
    },
    async getCedCommissionRoles() {
      try {
        let fake = []
        this.rolesCommissionData = []
        this.isBusy = true
        const data = await CeSetCommissionsService.getCedCommissionRoles()
        if (data.status === 200) {
          fake = data.data
          fake.forEach(commission => {
            commission.editing = false
            commission.savedData = 0
            commission.value = parseFloat(commission.value)
          })
          this.rolesCommissionData = fake
          setTimeout(() => {
            this.isBusy = false
          }, 900)
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
        this.isBusy = false
      }
    },
  },
}
</script>

<style scoped>
.lds-ring {
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
